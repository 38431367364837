import React, { useState } from 'react';
import {
  TextInput,
  Paper,
  Title,
  Container,
  Button,
  Image,
  Checkbox,
  Stack,
  Tooltip,
  Modal,
  Text,
} from '@mantine/core';

import MantelLogo from '../../assets/mantel_logo.svg';

export function Login(props) {
  const [userEmail, setUserEmail] = useState(null);
  const [submitted, setSubmitted] = useState(null);
  const [isError, setIsError] = useState(false);
  const [showInstructionsPopup, setShowInstructionsPopup] = useState(false);
  const [rememberDevice, setRememberDevice] = useState(true);

  const { onLogin } = props;

  const handleLogin = () => {
    if (!userEmail) {
      setIsError(true);
      return;
    }
    setIsError(false);
    setShowInstructionsPopup(true);
    setSubmitted(true); 
    onLogin(userEmail, rememberDevice);
  };

  return (
    <Container size={420} my={40}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 16,
        }}
      >
        <div data-test-id="meetId"
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: 12,
          }}
        >
          <Image src={MantelLogo} width={120} />
          <Title order={1} sx={{ lineHeight: 1, fontWeight: 300 }}>
            Mantel Meet
          </Title>
        </div>
        <Title
          order={3}
          sx={{
            fontWeight: 300,
          }}
          color={submitted && 'teal'}
          align='center'
        >
          {!submitted && 'Enter your email to login'}
        </Title>
      </div>
      <Paper withBorder shadow='md' p={30} mt={30} radius='md'>
        <Stack>
          <TextInput
            label='Email'
            placeholder='you@brand.com.au'
            onChange={(event) => setUserEmail(event.currentTarget.value)}
            error={isError && "Email cannot be empty, Please enter your email."}
            required
          />
          <Tooltip label="Saves your token to this device after completing the magic link signup flow (you won't need to sign in again on this device)">
            <Checkbox label='Remember me!' checked={rememberDevice} onChange={(event) => setRememberDevice(event.currentTarget.checked)}></Checkbox>
          </Tooltip>
          <Button fullWidth mt='xl' onClick={handleLogin}>
            Login
          </Button>
        </Stack>
      </Paper>
      <Modal 
        opened={showInstructionsPopup} 
        onClose={() => setShowInstructionsPopup(false)} 
        title="Almost there!"
        centered
      >
        <Text>
          We've sent you a message through the Timesheet Reminder bot on{" "}
          Slack. When you get the message, click on the magic link for access.
        </Text>
        <Text mt="sm">
          Note: it might take some time for the message to send.
        </Text>
      </Modal>
    </Container>
  );
}
