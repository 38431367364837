import { AginicIcon, AzenixIcon, CMDIcon, CuusooIcon, DigioIcon, EliizaIcon, IBAIcon, KasnaIcon, MantelGroupIcon, PretzelIcon } from '../components/MGIcons';

//TODO: Mantine color pallete and default css don't overlap, i.e. cant use "grape" in css and alternatively most components only support the mantine time colours
export const BRANDS = {
  azenix: { icon: AzenixIcon, color: "#2341E0", color_m: 'indigo' },
  cmd: { icon: CMDIcon, color:"#F47D20", color_m: 'orange' },
  digio: { icon: DigioIcon, color: "#7E57C5", color_m: 'grape' },
  mantelgroup: { icon: MantelGroupIcon, color: "#81CCEA", color_m: 'cyan.8' },
  eliiza: { icon: EliizaIcon, color: "#02AF90", color_m: 'green.5' },
  aginic: { icon: AginicIcon, color: "#FBB416", color_m: 'orange.5' },
  kasna: { icon: KasnaIcon, color: "#3CB15E", color_m: 'green' },
  ittybittyapps: { icon: IBAIcon, color: "#EA394E", color_m: 'red.6' },
  cuusoo: { icon: CuusooIcon, color: "#E9544E", color_m: 'red.9' },
  pretzellab: { icon: PretzelIcon, color: "#4AB181", color_m: 'cyan.3' },
};
