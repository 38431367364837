import { Paper, SimpleGrid, Stack, Text, Title } from '@mantine/core';
import React from 'react';
import useCustomBadges from '../../hooks/useCustomBadges';
import LoadingComponent from '../Loader';

const BadgeStats = ({ members, uid, token }) => {
  const badges = useCustomBadges(uid, token);

  return (
    <Stack>
      <Title order={2}>Badges</Title>
      <SimpleGrid cols={4} breakpoints={[{ maxWidth: 'sm', cols: 2 }]}>
        {badges ? (
          getBadgeStats(members, badges).map((badge) => (
            <Paper withBorder radius="md" p="xs" key={badge.id}>
              <Stack>
                <Text size={30}>{badge.icon}</Text>
                <div>
                  <Text 
                    color="dimmed" 
                    size="xs" 
                    transform="uppercase" 
                    weight={700}
                  >
                    {badge.label}
                  </Text>
                  <Text weight={700} size="xl">{badge.count}</Text>
                </div>
              </Stack>
            </Paper>
          )) 
        ) : (
          <LoadingComponent>Getting your badge stats...</LoadingComponent>
        )}
      </SimpleGrid>
    </Stack>
  );
};

const getBadgeStats = (members, badges) => {
  const badgeCounts = {};
  members.forEach((person) => {
    if (person.connection && person.connection.badges) {
      person.connection.badges.forEach((badge) => {
        // Check if key exists and increase count
        badgeCounts[badge] = (badgeCounts[badge] || 0) + 1;
      })
    }
  });

  const badgeStats = Object.keys(badgeCounts)
    .map((badgeId) => {
      const badgeData = badges.find((badge) => badgeId === badge.id);
      return { ...badgeData, count: badgeCounts[badgeId] };
    })
    .sort((a, b) => b.count - a.count);

  return badgeStats;
};

export default BadgeStats;
