import { Container } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';

import React, { useState, useEffect, useCallback } from 'react';

import Header from '../Header';
import { Mantelorians } from '../Mantelorians';
import { Connections } from '../Connections';
import Stats from '../Stats';
import QuizGame from '../QuizGame';

const LINKS = [
  {
    tab: 'mantelorians',
    label: 'Mantelorians',
  },
  {
    tab: 'connections',
    label: 'My Connections',
  },
  {
    tab: 'stats',
    label: 'Stats',
  },
  {
    tab: 'quizGame',
    label: 'Guess Who',
  },
];

export default function Dashboard(props) {
  const { members, uid, token } = props;

  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState('mantelorians');
  const [filteredMembers, setFilteredMembers] = useState(null); //nonConnectedMembers

  useEffect(() => {
    const self = members.find((member) => member.isSelf);
    setUser(self);
  }, [members]);

  useEffect(() => {
    setFilteredMembers(members.filter((member) => !member.connection));
  }, [members]);

  //Advanced debouced filtering
  const debouncedFilterHandler = useCallback(debounce(onFilterUpdate, 300));

  function onFilterUpdate(filterKey) {
    return setFilteredMembers(filterMembers(members, filterKey));
  }

  return (
    <NotificationsProvider position='top-left' zIndex={2077}>
      {user && (
        <>
          <Header
            links={LINKS}
            onTabChange={setActiveTab}
            name={user.normalised_name}
            user={user}
            uid={uid}
            token={token}
          />
          {/* Lazy way to do page navigation until react-router is set up or use actual tab component */}
          {activeTab === 'mantelorians' && members && (
            <Mantelorians
              members={
                filteredMembers
                  .filter((member) => !member.connection && !member.isSelf)
                  .sort(mantelorianSort)
              }
              uid={uid}
              token={token}
              filterHandler={debouncedFilterHandler}
            />
          )}

          {activeTab === 'stats' && (
            <Container>
              {members && <Stats members={members} uid={uid} token={token} />}
            </Container>
          )}
          {activeTab === 'connections' && (
            <Container>
              <Connections
                uid={uid}
                token={token}
                connections={
                  members
                    .filter((member) => member.connection)
                    .sort(connectionSort)
                }
              />
            </Container>
          )}
          {activeTab === 'quizGame' && (
            <Container size='lg'>
              <QuizGame
                uid={uid}
                token={token}
                data={
                  members.filter((member) => !member.isSelf && member.image)
                }
                brand={user.brand}
              />
            </Container>
          )}
        </>
      )}
    </NotificationsProvider>
  );
}

const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

function connectionSort(a, b) {
  return a.connection.isCreated ? -1 : b.connection._ts - a.connection._ts;
}

function mantelorianSort(a, b) {
  //TODO: should be sorted by startDate, at the moment just going to sort by created day, quick way to get new starters at the top
  return b._ts - a._ts;
}

function filterMembers(members, filterKey) {
  // const namePredicate = (member) => member.name && member.name.includes(filterKey);
  // const brandPredicate = (member) => member.brand && member.brand.includes(filterKey);
  const badgePredicate = (member) =>
    member.connection &&
    member.connection.badges &&
    member.connection.badges.includes(filterKey); //TODO: At the momemnt this will need to be an exact match

  const searchPredicate = (member) =>
    [member.name, member.brand]
      .filter(Boolean)
      .join('#')
      .toLowerCase()
      .includes(filterKey.toLowerCase());

  return members.filter(
    (member) => searchPredicate(member) || badgePredicate(member)
  );
}
