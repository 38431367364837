export const CONNECTION_OPTIONS = [
    {
        id: "coffee",
        label: "Coffee catch up",
        icon: "☕️",
    },
    {
        id: "project",
        label: "On the same work project",
        icon: "💻",
    },
    {
        id: "social",
        label: "At the office / social event",
        icon: "💬",
    },
    {
        id: "virtual",
        label: "Connected virtually",
        icon: "☁️",
    },
];

export const badges = [
    {
        id: "coffee",
        label: "Coffee catch up",
        icon: "☕️",
        type: "regular",
    },
    {
        id: "project",
        label: "On the same work project",
        icon: "💻",
        type: "regular",
    },
    {
        id: "social",
        label: "At the office / social event",
        icon: "💬",
        type: "regular",
    },
    {
        id: "virtual",
        label: "Connected virtually",
        icon: "☁️",
        type: "regular",
    },
    {
        id: "sc:easter23",
        label: "Easter Social",
        icon: "🥚",
        type: "custom",
    },
    {
        id: "hub:melb_eking",
        label: "Melbourne Hub",
        icon: "👻",
        type: "custom",
    },
    {
        id: "tr:fa23",
        label: "Future Associates - FY23 supergroup!",
        icon: "∙",
        type: "custom",
    },
    {
        id: "hub:world_msedrak",
        label: "Where in the world is Mark",
        icon: "🌏",
        type: "custom",
    },
    {
        id: "event:melb_eofy23",
        label: "EOFY 2023 Meetup - Melbourne Hub",
        icon: "🥂",
        type: "custom",
        dateStart: "2023-06-28",
        dateEnd: "2023-07-02",
    },
    {
        id: "event:syd_eofy23",
        label: "EOFY 2023 Meetup - Sydney Hub",
        icon: "🍻",
        type: "custom",
        dateStart: "2023-06-28",
        dateEnd: "2023-06-01",
    },
    {
        id: "event:ski_trip_qt_23",
        label: "Ski Trip 2023 - Queenstown",
        icon: "🏂",
        type: "custom",
        dateStart: "2023-08-07",
        dateEnd: "2023-09-18",
    },
    {
        id: "event:ski_trip_vic_23",
        label: "Ski Trip 2023 - Melbourne",
        icon: "⛷️",
        type: "custom",
        dateStart: "2023-08-07",
        dateEnd: "2023-09-18",
    },
    {
        id: "event:ski_trip_nsw_23",
        label: "Ski Trip 2023 - Sydney",
        icon: "🎿",
        type: "custom",
        dateStart: "2023-08-07",
        dateEnd: "2023-09-18",
    },
];

export const getUserEvents = (badges, uid) => {
    if (badges) {
        return badges.filter((badge) => (
            badge.members && badge.members.includes(uid)
        ));
    } else {
        return [];
    }
};
