import React from 'react';
import { Stack } from '@mantine/core';
import BrandStats from './BrandStats';
import BadgeStats from './BadgeStats';

const Stats = ({ members, uid, token }) => {
  return (
    <Stack>
      <BrandStats members={members} />
      <BadgeStats members={members} uid={uid} token={token} />
    </Stack>
  );
};

export default Stats;
