import {
  Text,
  Stack,
  Title,
  Button,
  Checkbox,
  Chip,
  Group,
  Radio,
} from '@mantine/core';

import React, { useState, useEffect } from 'react';

import GuessWho from './GuessWho';
import { ConnectionModal } from '../ConnectionModal';
import LoadingComponent from '../Loader';
import { processConnectionUpdate } from '../../utils/processConnectionUpdate';
import { getUserEvents } from '../../utils/badges';
import useCustomBadges from '../../hooks/useCustomBadges';

const DEFAULT_GAME_OPTIONS = ['include-connections'];

export default function QuizGame({ data, brand, uid, token }) {
  const [gameState, setGameState] = useState('info');
  const [gameMode, setGameMode] = useState("default");
  const [gameOptions, setGameOptions] = useState(DEFAULT_GAME_OPTIONS);
  const [mantelorians, setMantelorians] = useState(data);
  const [correctPeeps, setCorrectPeeps] = useState([]);

  //TODO: Refactor the connection log into it's own seperate component
  const [activeConnection, setActiveConnection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessfulConnection, setIsSuccessfulConection] = useState(null);

  const badges = useCustomBadges(uid, token);

  const handleModalClose = () => {
    // reset everything to make another connection
    setIsModalOpen(false);
    setIsSuccessfulConection(null);
    setIsError(false);
  };

  const updateConnection = async (item, connectionCtx) => {
    setIsLoading(true);

    try {
      await processConnectionUpdate(uid, item, connectionCtx, token);
      setIsSuccessfulConection(true);
      setIsLoading(false);
      item.connection = connectionCtx; // Local update
    } catch (err) {
      console.log('Error', err);
      setIsError(true);
    }
  };

  useEffect(() => {
    let filteredMantelorians = data;

    if (gameMode === "brand-only") {
      filteredMantelorians = filteredMantelorians.filter((mantelorian) => (
        mantelorian.brand === brand
      ));
    } else if (gameMode.startsWith("event-")) {
      const selectedEvent = badges.find((badge) => (
        // 6 because event- is 6 characters and all event related game modes are
        // prefixed with event-
        badge.id === gameMode.slice(6)
      ));
      filteredMantelorians = filteredMantelorians.filter((mantelorian) => (
        selectedEvent.members.includes(mantelorian.id)
      ));
    }

    if (!gameOptions.includes('include-connections')) {
      filteredMantelorians = filteredMantelorians.filter((mantelorian) => (
        !mantelorian.connection
      ));
    }
    
    setMantelorians(filteredMantelorians);
  }, [data, brand, badges, gameMode, gameOptions]);

  const gameReset = () => {
    setCorrectPeeps([]);
    setGameState('info');
    setGameOptions(DEFAULT_GAME_OPTIONS);
  }

  return (
    <Stack>
      {gameState === 'started' && (
        <GuessWho 
          data={mantelorians} 
          setGameState={setGameState} 
          correctPeeps={correctPeeps}
          setCorrectPeeps={setCorrectPeeps}
        />
      )}
      
      {gameState === 'info' && (
        <Stack align='flex-start' sx={{ width: '60%', marginTop: 48 }}>
          <Title order={1}>Guess Who?</Title>
          <Text>
            So you think you know some{' '}
            <span style={{ fontWeight: 700 }}>Mantelorians?</span>
          </Text>
          <Text>
            <span style={{ fontWeight: 700 }}>Test your knowledge</span> by
            playing a game of Guess Who. <br />
            The game is simple, a picture of a friendly face{' '}
            <span role='img' aria-label='smiley-face' style={{ fontSize: 18 }}>
              😁
            </span>{' '}
            will appear and all you have to do is match the face to the correct
            name.
          </Text>
          <Text>
            You'll have an opportunity at the end to{' '}
            <span style={{ fontWeight: 700 }}>add connections</span> to those
            you have already met or{' '}
            <span style={{ fontWeight: 700 }}>reach out</span> to some of the
            faces you didn't quite recognise{' '}
            <span
              role='img'
              aria-label='embarrassed-emoji'
              style={{ fontSize: 18 }}
            >
              🥴
            </span>
          </Text>
          <Stack mt={8}>
            <Text weight={700}>Game Modes</Text>
            <Radio.Group
              orientation='vertical'
              value={gameMode}
              onChange={setGameMode}
              size="md"
            >
              <Radio color="red" value="default" label="Default" />
              <Radio 
                color="red"
                value="brand-only"
                label={`${brand.charAt(0).toUpperCase() + brand.slice(1)} only`}
              />
              {badges
                ? getUserEvents(badges, uid).map((badge) => (
                    <Radio
                      key={`game-mode-event-${badge.id}`}
                      color="red"
                      value={`event-${badge.id}`}
                      label={`${badge.icon} ${badge.label} only`}
                    />
                  ))
                : (
                  <LoadingComponent>
                    Checking if there are more game modes for you...
                  </LoadingComponent>
                )
              }
            </Radio.Group>
          </Stack>
          <Stack my={8}>
            <Text weight={700}>Game Options</Text>
            <Checkbox.Group 
              orientation='vertical' 
              value={gameOptions} 
              onChange={setGameOptions} 
              size="md"
            >
              <Checkbox 
                color='red' 
                value='include-connections' 
                label="Include faces I have already connected with"
              />
            </Checkbox.Group>
          </Stack>
          <Button
            onClick={() => setGameState('started')}
            variant='filled'
            color='#D86E89'
            sx={{
              background: '#D86E89',
              '&:hover': {
                background: '#d15474',
              },
            }}
          >
            Let's Play{' '}
            <span role='img' aria-label='dice' style={{marginLeft: 8}}>🎲</span>
          </Button>
        </Stack>
      )}

      {gameState === 'ended' && (
        <Stack
          align='flex-start'
          sx={{
            width: '60%',
            marginTop: 48,
          }}
        >
          <Title>
            Total score: {correctPeeps.length}
          </Title>
          <Button
            onClick={() => gameReset()}
            variant='filled'
            color='#D86E89'
            sx={{
              background: '#D86E89',
              '&:hover': {
                background: '#d15474',
              },
            }}
          >
            Play again{' '}
            <span role='img' aria-label='dice' style={{marginLeft: 8}}>🤩</span>
          </Button>
          <Group>
            {correctPeeps.map((p) => {
              return (
                <Chip 
                  key={p.email}
                  color='cyan' 
                  disabled={p.connection} 
                  checked={p.connection} 
                  onClick={() => {
                    setActiveConnection(p);
                    setIsModalOpen(true);
                  }}
                >
                  {p.name}
                </Chip>
              );
            })}
          </Group>

          <ConnectionModal
            opened={isModalOpen}
            closeModal={handleModalClose}
            addConnection={updateConnection}
            connection={activeConnection}
            isLoading={isLoading}
            isError={isError}
            isSuccess={isSuccessfulConnection}
          />
        </Stack>
      )}
    </Stack>
  );
}
