import React, { useEffect, useState } from 'react';
import {
  Text,
  Avatar,
  SimpleGrid,
  Paper,
  Group,
  Stack,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';

export default function GuessWho({ 
  data, 
  setGameState, 
  correctPeeps, 
  setCorrectPeeps,
}) {
  // Peeps in the round
  const [peeps, setPeeps] = useState([]);
  const [peep, setPeep] = useState();

  //Peeps in the game
  const [remainingPeeps, setRemainingPeeps] = useState(data);

  // Basic scoring for now, TODO: make more engaging
  const [timeRemaining, setTimeRemaining] = useState(20);

  //time countdown 
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining(timeRemaining - 1);
      } else {
        setGameState('ended');
      }
    }, 1000);
  
    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * remainingPeeps.length);
    let peepSlice = [];
    if (randomNumber + 4 < remainingPeeps.length) {
      peepSlice = remainingPeeps.slice(randomNumber, randomNumber + 4);
    } else {
      peepSlice = randomNumber - 4 > 0
        ? remainingPeeps.slice(randomNumber - 4, randomNumber)
        : remainingPeeps.slice(0, 4);
    }
    setPeeps(peepSlice);

    const randomPeep = Math.floor(Math.random() * peepSlice.length);
    setPeep(peepSlice[randomPeep]);
  }, [remainingPeeps, setGameState]);

  useEffect(() => {
    if (remainingPeeps.length < 2 || timeRemaining === 0) {
      setGameState('ended');
    }
  }, [remainingPeeps, timeRemaining, setGameState]);

  const handleSelection = (index) => {
    peep.email === peeps[index].email ? correctAnswer() : wrongAnswer();
  };

  const wrongAnswer = () => {
    setTimeRemaining(timeRemaining - 5 >= 0 ? timeRemaining - 5 : 0);
    showNotification({
      message: '🥵! -5 sec',
      autoClose: 2000,
      color: 'red',
      sx: { width: '180px' },
    });
  }

  const correctAnswer = () => {
    setCorrectPeeps([...correctPeeps, peep]);
    setRemainingPeeps(remainingPeeps.filter((p) => p.email !== peep.email));
    setPeep('');
    setTimeRemaining(timeRemaining + 10);
    showNotification({
      message: '🥳! +10 sec',
      autoClose: 2000,
      color: 'green',
      sx: { width: '180px' },
    });
  }

  const peepUI = peeps.map((peep, index) => (
    <Paper
      style={{
        height: '160px',
        display: 'flex',
        cursor: 'pointer',
      }}
      sx={{
        '&:hover': {
          borderColor: '#1E5E82',
        },
      }}
      withBorder
      radius='md'
      p='xs'
      onClick={() => handleSelection(index)}
      key={peep.email}
    >
      <Group
        position='center'
        sx={{ alignItems: 'center', width: '100%' }}
      >
        <div height='200px'>
          <Text fw={700}>{peep.name}</Text>
        </div>
      </Group>
    </Paper>
  ));

  return (
    <Stack my='md'>
      <Title>
        Guess <span style={{ fontWeight: 300 }}>Who?</span>
      </Title>
      <Text>
        Quick! You only have{' '}
        <span style={{ fontWeight: 700 }}>{timeRemaining} seconds</span> to guess the
        name
      </Text>
      <Group position="apart">
        <Group>
          <Title>
            <span role='img' aria-label='thumbs up'>👍</span>
            {' '}
            {correctPeeps.length} / {data.length}
          </Title>
        </Group>
      </Group>
      <div
        style={{
          width: '100%',
          height: '400',
          // backgroundColor: theme.colors.gray[1],
          position: 'relative',
        }}
      >
        <SimpleGrid
          spacing={'xl'}
          height={'400px'}
          cols={2}
          breakpoints={[{ maxWidth: 'sm', cols: 2 }]}
        >
          {peepUI}
        </SimpleGrid>

        <div>
          <Avatar
            size={48}
            radius={24}
            src={peep && peep.image_192}
            style={{
              position: 'absolute',
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              width: 128,
              height: 128,
            }}
          />
        </div>
      </div>
    </Stack>
  );
}
