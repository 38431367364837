import { 
  Center,
  Group,
  Paper,
  RingProgress,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React from 'react';
import { groupBy } from '../../utils/groupBy';
import { BRANDS } from '../../utils/brandIcons';
import { 
  AginicIcon,
  AzenixIcon,
  CMDIcon,
  CuusooIcon,
  DigioIcon,
  EliizaIcon,
  IBAIcon,
  KasnaIcon,
  MantelGroupIcon,
  PretzelIcon 
} from '../MGIcons';

const BrandStats = ({ members }) => {

  const brandStatCards = getBrandStats(members).map((stat) => (
    <Paper withBorder radius="md" p="xs" key={stat.label}>
      <Group>
        <RingProgress
          size={80}
          roundCaps
          thickness={8}
          sections={[{ value: stat.progress, color: stat.color }]}
          label={
            <Center>
              {getBrandIcon(stat.label, { height: '22px' })}
            </Center>
          }
        />
        <div>
          <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
            {stat.label}
          </Text>
          <Text weight={700} size="xl">
            {stat.stats}
          </Text>
        </div>
      </Group>
    </Paper>
  ));

  return (
    <Stack>
        <Title order={2}>Brands</Title>
        <SimpleGrid cols={4} breakpoints={[{ maxWidth: 'sm', cols: 2 }]}>
          {brandStatCards}
        </SimpleGrid>
      </Stack>
  );
};

const getBrandStats = (members) => {
  const groupedByBrand = groupBy(members, 'brand');

  const brandStats = Object.entries(groupedByBrand)
    .map(([brand, collection]) => {
      const brandCount = collection.length;
      const catchups = collection.filter((c) => c.connection || c.isSelf);

      // Since brand is mapped, there should always be atleast 1 person from
      // the brand
      const percentage = (catchups.length / brandCount) * 100;

      return {
        label: brand,
        stats: '' + Number(percentage).toFixed(2) + '%',
        progress: percentage,
        color: BRANDS[brand].color,
      };
    })
    .sort((a, b) => b.progress - a.progress);

  return brandStats;
};

// TODO: better aproach for brand icon switch this is very basic
const getBrandIcon = (brand, props) => {
  switch (brand) {
    case 'digio':
      return (<DigioIcon {...props} />)
    case 'cmd':
      return (<CMDIcon {...props} />)
    case 'ittybittyapps':
      return (<IBAIcon {...props} />)
    case 'eliiza':
      return (<EliizaIcon {...props} />)
    case 'azenix':
      return (<AzenixIcon {...props} />)
    case 'cuusoo':
      return (<CuusooIcon {...props} />)
    case 'kasna':
      return (<KasnaIcon {...props} />)
    case 'pretzellab':
      return (<PretzelIcon {...props} />)
    case 'aginic':
      return (<AginicIcon {...props} />)
    case 'mantelgroup':
    default:
      return (<MantelGroupIcon {...props} />)
  }
};

export default BrandStats;
