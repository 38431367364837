import React, { forwardRef, useEffect, useState } from "react";
import { Group, Avatar, Text, Menu, UnstyledButton } from "@mantine/core";
import QRCodeModal from "../QRCodeModal";
import useCustomBadges from "../../hooks/useCustomBadges";
import { CONNECTION_OPTIONS, getUserEvents } from "../../utils/badges";

const UserButton = forwardRef(
  ({ image, ...others }, ref) => (
    <UnstyledButton
      ref={ref}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        '&:hover': {
          backgroundColor:
          theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      })}
      
      {...others}
    >
      <Group>
        <Avatar src={image} radius="xl" />
        <Text size="1rem">&#x203A;</Text>
      </Group>
    </UnstyledButton>
  )
);

const socialBadge = CONNECTION_OPTIONS.find(badge => badge.id === "social");
const defaultConnectionOption = {
  name: socialBadge.label,
  id: socialBadge.id,
};

export default function UserMenu({ image, name, email, uid, token }) {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const initialBadgeId = queryParameters.get('badgeId') || undefined;
  const [badgeId, setBadgeId] = useState(initialBadgeId);
  const [eventList, setEventList] = useState([defaultConnectionOption]);

  const badges = useCustomBadges(uid, token);
  
  useEffect(() => {
    if (badges) {
      const activeBadges = badges.filter(badge => isBadgeActive(badge));
      const userEvents = getUserEvents(activeBadges, uid);
      
      const eventList = userEvents.map(event => ({
        name: event.label,
        id: event.id,
      }));
      setEventList([defaultConnectionOption, ...eventList]);
    }
  }, [badges, uid]);

  return (
    <>
      <QRCodeModal 
        email={email} 
        eid={badgeId} 
        setBadgeId={setBadgeId} 
        events={eventList} 
        isModalOpened={isModalOpened} 
        setIsModalOpened={setIsModalOpened} 
      />
      <Group position="center">
        <Menu>
          <Menu.Target>
            <UserButton
                image={image}
            />
          </Menu.Target>
          
          <Menu.Dropdown>
            <Menu.Item>
              <Text size="sm" weight={500}>{name}</Text>
        
              <Text color="dimmed" size="xs">{email}</Text>
            </Menu.Item>

            <Menu.Divider />
            
            {/* <Menu.Item component="a" href="">Edit Profile</Menu.Item> */}
            
            <Menu.Item onClick={()=> setIsModalOpened(true)}>
              Generate a QR Code
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </>
  );
}

const isBadgeActive = (badge) => {
  if (!badge.id.startsWith("event:")) {
    return false;
  }
  if (!badge.dateStart || !badge.dateEnd) {
    return false;
  }
  const startDate = new Date(badge.dateStart);
  const endDate = new Date(badge.dateEnd);
  const today = new Date();
  return today >= startDate && today <= endDate;
};
