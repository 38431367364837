export async function processConnectionUpdate(uid, item, connectionCtx, token) {

  if (process.env.NODE_ENV === 'development') {
    setTimeout(() => {
     console.log('Local call, Todo: uplift to point to a sandbox without cors');
     console.log('uid', uid);
     console.log('item', JSON.stringify(item));
    }, 2000);
  } else {

    const body = {
      uid,
      cid: item.id,
      connectionCtx,
    };

    const options = {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'x-mg-authorization': `Bearer ${token}`,
      },
    };
    try {
      await (await fetch(`/api/mantelorians`, options)).json();
    } catch (err) {
      console.log('Error', err);
    }
  }
}
