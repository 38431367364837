import {
  Avatar,
  Badge,
  Table,
  Group,
  Text,
  ActionIcon,
  useMantineTheme,
  Button,
  Image,
  Center,
  Container,
  TextInput,
  Title
} from '@mantine/core';
import React, { useState } from 'react';

import { useMediaQuery } from '@mantine/hooks';

import { ConnectionModal } from '../ConnectionModal';

import SlackLogo from '../../assets/slack_logo.svg';
import AddConnectionIcon from '../../assets/add_connection_icon.svg';
import ConnectedIcon from '../../assets/connected_icon.svg';
import { processConnectionUpdate } from '../../utils/processConnectionUpdate';
import { BRANDS } from '../../utils/brandIcons';

export function Mantelorians({ 
  members,
  uid,
  token,
  filterHandler
}) {
  const [activeConnection, setActiveConnection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccessfulConnection, setIsSuccessfulConection] = useState(null);
  const [dataChunkSize, setDataChunkSize] = useState(50);
  const theme = useMantineTheme();
  const isMobileView = useMediaQuery('(max-width: 900px)');

  const updateConnection = async (item, connectionCtx) => {
    setIsLoading(true);

    try {
      await processConnectionUpdate(uid, item, connectionCtx, token);
      setIsSuccessfulConection(true);
      setIsLoading(false);
      item.connection = { isCreated: true, ...connectionCtx }
      handleModalClose(); //Fast connect for now, i.e. wont show the last screen when creating new connections
    } catch (err) {
      console.log('Error', err);
      setIsError(true);
    }

  };

  const handleModalClose = () => {
    // reset everything to make another connection
    setIsModalOpen(false);
    setIsSuccessfulConection(null);
    setIsError(false);
  };

  const rows = members.slice(0, dataChunkSize).map((item) => (
    <tr key={item.name}>
      <td>
        <Group spacing='sm'>
          <Avatar size={24} src={item.image_24} radius={24} />
          <div>
            <Text size='sm' weight={500}>
              {item.name}
            </Text>
            {!isMobileView && (
              <Text color='dimmed' size='xs'>
                {item.title}
              </Text>
            )}
          </div>
        </Group>
      </td>
      {!isMobileView && (
        <td>
          <Badge
            color={BRANDS[item.brand.toLowerCase()].color_m}
            variant={theme.colorScheme === 'dark' ? 'light' : 'outline'}
          >
            {item.brand}
          </Badge>
        </td>
      )}
      {!isMobileView && (
        <td>
          <Text size='sm' href='#' onClick={(event) => event.preventDefault()}>
            {item.email}
          </Text>
        </td>
      )}
      <td>
        <Group position='left' noWrap>
          {isMobileView ? (
            <ActionIcon
              component='a'
              href={'https://slack.com/app_redirect?channel=' + item.sId}
              target='_blank'
            >
              {Icon(SlackLogo)}
            </ActionIcon>
          ) : (
            <Button
              component='a'
              href={'https://slack.com/app_redirect?channel=' + item.sId}
              target='_blank'
              variant='outline'
              color='#1E5E82'
              leftIcon={Icon(SlackLogo)}
              sx={{
                borderColor: '#1E5E82',
                color: '#1E5E82',
                '&:hover': {
                  background: 'rgba(30, 94, 130, 0.2)',
                },
              }}
            >
              Slack
            </Button>
          )}
          <ConnectionActions
            item={item}
            isMobileView={isMobileView}
            addConnection={() => {
              setActiveConnection(item);
              setIsModalOpen(true);
            }}
          />
        </Group>
      </td>
    </tr>
  ));

  return (
    <Container size='lg'>
      <Title
        order={3}
        color='dimmed'
        style={{
          fontWeight: 300,
          maxWidth: 600,
        }}
      >
        Here are some Mantelorians you{' '}
        <span style={{ fontWeight: 500 }}>haven't connected with yet.</span>
      </Title>
      <Container my={16} size='lg'>
        <TextInput
          placeholder='Filter by name or brand'
          onChange={(event) => filterHandler(event.currentTarget.value)}
        />
      </Container>
      <Table verticalSpacing='sm'>
        <thead>
          <tr>
            <th>Mantelorians</th>
            {!isMobileView && <th>Brand</th>}
            {!isMobileView && <th>Email</th>}
            <th />
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      {members.length > dataChunkSize && (
        <Center>
          <Button
            color={'cyan'}
            compact
            onClick={() => setDataChunkSize(dataChunkSize + 200)}
          >
            Show more
          </Button>
        </Center>
      )}

      <ConnectionModal
        opened={isModalOpen}
        closeModal={handleModalClose}
        addConnection={updateConnection}
        connection={activeConnection}
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccessfulConnection}
      />
    </Container>
  );
}

const Icon = (icon, size = 24) => {
  return <Image src={icon} width={size} />;
};

const ConnectionActions = (props) => {
  const { item, isMobileView, addConnection } = props;
  if (item.connection) {
    if (isMobileView) {
      return Icon(ConnectedIcon);
    } else {
      return (
        <Badge
          size='xl'
          radius='xs'
          variant='light'
          color='green'
          sx={{
            fontSize: 14,
            textTransform: 'none',
          }}
        >
          Connected
        </Badge>
      );
    }
  } else {
    if (isMobileView) {
      return (
        <ActionIcon onClick={() => addConnection(item)}>
          {Icon(AddConnectionIcon)}
        </ActionIcon>
      );
    } else {
      return (
        <Button
          leftIcon={Icon(AddConnectionIcon)}
          onClick={() => addConnection(item)}
          variant='outline'
          sx={{
            borderColor: '#D86E89',
            color: '#D86E89',
            '&:hover': {
              background: 'rgba(216, 110, 137, 0.2);',
            },
          }}
        >
          Connect
        </Button>
      );
    }
  }
};
