import React, { useState } from 'react';
import {
  Card,
  Image,
  Text,
  Button,
  Stack,
  SimpleGrid,
  Space,
  Group,
  ActionIcon,
  Avatar,
} from '@mantine/core';
import { BRANDS } from '../../utils/brandIcons';
import { ConnectionModal } from '../ConnectionModal';
import useCustomBadges from '../../hooks/useCustomBadges';
import { processConnectionUpdate } from '../../utils/processConnectionUpdate';
import LoadingComponent from '../Loader';

// data.name
// data.title
// data.image_192
// data.brand
// data.connection.badges

export function Connections({ connections, uid, token }) {
  const [activeConnection, setActiveConnection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccessfulConnection, setIsSuccessfulConection] = useState(null);

  const badges = useCustomBadges(uid, token);

  const handleModalClose = () => {
    // reset everything to make another connection
    setIsModalOpen(false);
    setIsSuccessfulConection(null);
    setIsError(false);
  };

  const updateConnection = async (item, connectionCtx) => {
    setIsLoading(true);

    try {
      await processConnectionUpdate(uid, item, connectionCtx, token);
      setIsSuccessfulConection(true);
      setIsLoading(false);
      item.connection = connectionCtx; // Local update
    } catch (err) {
      console.log('Error', err);
      setIsError(true);
    }

  };

  const brandIcon = (connection) => {
    const Component = BRANDS[connection.brand.toLowerCase()].icon
    return <Component height='18px' />
  }

  return (
    <SimpleGrid cols={4} breakpoints={[{ maxWidth: 'sm', cols: 2 }]}>
      {connections.map((connection) => (
        <Card 
          key={connection.email} 
          shadow='sm' 
          p='lg' 
          radius='md' 
          withBorder 
          color={BRANDS[connection.brand.toLowerCase()].color} 
          sx={{ borderColor: BRANDS[connection.brand.toLowerCase()].color }} 
        >
          <Card.Section color={BRANDS[connection.brand.toLowerCase()].color}>
            <Group p='xs' spacing='xs'>
              <Avatar size='xs' variant='transparent'>
                {brandIcon(connection)}
              </Avatar>
              <Text lineClamp={1} size='sm'>{connection.name}</Text>
            </Group>
            <Image
              src={connection.image_192}
              height={192}
              alt='Connection avatar'
            />
          </Card.Section>

          <Stack align='flex-start' spacing='md' py={10}>

            {/* <Badge color={BRANDS[connection.brand.toLowerCase()].color} variant='light'>
              {connection.brand}
            </Badge> */}

            {connection.title ? (
              <Text size='sm' color='dimmed' lineClamp={1}>
                {connection.title}
              </Text>
            ) : (
              <Space h='lg' />
            )}

            <Group spacing='4px'>
              {badges 
                ? badges
                    .filter(custom => connectionHasBadge(connection, custom))
                    .map(option => (
                      <ActionIcon
                        key={connection.email + "_" + option.id}
                        radius={'md'}
                        size='md'
                        variant={'filled'}
                        color='blue.0'
                      >
                        <span style={{ opacity: 1 }}>
                          {option.icon}
                        </span>
                      </ActionIcon>
                    ))
                : <LoadingComponent></LoadingComponent>
              }
            </Group>
            <Button variant='light' color='blue' fullWidth mt='md' radius='md' onClick={() => {
              setActiveConnection(connection);
              setIsModalOpen(true);
            }}>
              Edit connection
            </Button>
          </Stack>
        </Card>
      ))}
      <ConnectionModal
        opened={isModalOpen}
        closeModal={handleModalClose}
        addConnection={updateConnection}
        connection={activeConnection}
        isLoading={isLoading}
        isError={isError}
        isSuccess={isSuccessfulConnection}
      />
    </SimpleGrid>
  );
}
function connectionHasBadge(connection, option) {
  return connection.connection &&
    connection.connection.badges &&
    connection.connection.badges.includes(option.id);
}

