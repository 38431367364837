import React from 'react';
import { Stack, Loader } from '@mantine/core';

export default function LoadingComponent({ children }) {
  return (
    <Stack align='center' spacing='xl' p={10}>
      <Loader size='xl' variant='dots' color='#D86E89' />
      {children}
    </Stack>
  );
}
