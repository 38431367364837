import React from 'react';
import QRCode from "react-qr-code";
import { Modal, Group, Button } from "@mantine/core";

export const QRCodeModal = ({
  email, 
  eid,
  setBadgeId,
  events,
  isModalOpened,
  setIsModalOpened,
}) => {
  const closeAndRest = () => {
    setBadgeId(undefined);
    setIsModalOpened(false);
  }
  const qrcontent = `https://www.mg-mantelorians.app/?cid=${email}${eid ? '&eid=' + eid : ''}`;

  return (
    <Modal 
      opened={isModalOpened} 
      onClose={() => closeAndRest()} 
      title={!!eid ? "Your QR Code" : "Select Event"}
      centered
    >
      {!!eid ? (
        <div className='qr-container'>
          <QRCode
            value={qrcontent}
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            viewBox={`0 0 256 256`}
          />
        </div>
      )
      : ( 
        <Group>
          {events && events.map((event) => (
            <Button 
              variant="outline" 
              key={event.id} 
              onClick={() => setBadgeId(event.id)}
            >
              {event.name}
            </Button>
          ))}
        </Group>
      )}
    </Modal>
  )
}


export default QRCodeModal;