import React, { useEffect, useState } from 'react';
import { MantineProvider, Title } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';

// import MANTELORIAN_DATA from './fixtures/mantelorians.json'; // DEV: import this to local instance

import { Login } from './components/Login';

import Dashboard from './components/Dashboard';
import LoadingComponent from './components/Loader';

function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  
  // Remember device's local storage
  const [localToken, setLocalToken] = useLocalStorage({ key: 'local-token'});
  const [localUid, setLocalUid] = useLocalStorage({ key: 'local-uid'});
  
  const uid = queryParameters.get('uid') || localUid;
  const token = queryParameters.get('t') || localToken;
  const cid = queryParameters.get('cid'); //grabs a connection id from the query param
  const eid = queryParameters.get('eid'); //grabs an event id from the query param, to be used for custom events/badges

  const [loading, setLoading] = useState(false);
  const [allMembers, setAllMembers] = useState(null);

  const fetchMantelorians = async (userId, token, cid, eid) => {
    setLoading(true);
    let collection;
    if (process.env.NODE_ENV === 'development') {
      // collection = MANTELORIAN_DATA && MANTELORIAN_DATA.collection; //Used for quick local development //TODO: sync with Izzy
    } else {
      //Make connection API call if connection id present, either
      if(cid) {
        const options = {
          method: 'post',
          body: JSON.stringify({ uid: userId, cList: [cid], connectionCtx: { badges: ["social", eid].filter(Boolean) } }),
          headers: {
            'Content-Type': 'application/json',
            'x-mg-authorization': `Bearer ${token}`,
          },
        };
        await fetch(`/api/connections`, options)
      }  

      const mantelorianOptions = {
        method: 'post',
        body: JSON.stringify({ uid: userId }),
        headers: {
          'Content-Type': 'application/json',
          'x-mg-authorization': `Bearer ${token}`,
        },
      };
      collection = (await (await fetch(`/api/mantelorians`, mantelorianOptions)).json())
        .collection;
    }

    //TODO: uplift the user flow based on JWT and not API, this is a hotfix 
    const loggedInMember = collection.find(member => member.id === userId);
    loggedInMember.isSelf = true; 

    setAllMembers(collection);
    setLoading(false);
  };

  useEffect(() => {
    if (token && uid) {
      fetchMantelorians(uid, token, cid, eid);
    }
  }, [uid, token, cid, eid]);

  useEffect(() => {
    //Remember device flow, sanity check to make sure the magic link userId and the remembered uid is the same
    if(token && localUid && localUid === uid){
      setLocalToken(token); // Completes the remember flow, both token and uid saved to storage and can log in directly from same device w/o the query params
    }
  }, [localUid, token, uid, setLocalToken])

  const onUserLogin = (uid, rememberDevice) => {
    const formatttedUid = uid.toLowerCase();
    const passedQueryParams = new URLSearchParams(window.location.search);

    // make sure to remove any uid or token query params
    passedQueryParams.delete('uid')
    passedQueryParams.delete('t')

    fetch(`/api/magiclink?uid=${formatttedUid}${passedQueryParams ? `&queryParameters=${encodeURIComponent(passedQueryParams)}` : ''}`);

    if (rememberDevice) {
      setLocalUid(uid);
    }
  };

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{ colorScheme: 'light' }}
    >
      {allMembers ? (
        <Dashboard 
          members={allMembers}
          uid={uid}
          token={token}
        />
      ) : loading ? (
        <LoadingComponent>
          {' '}
          <Title
            align='center'
            sx={{
              fontSize: 20,
              fontWeight: 300,
            }}
          >
            <span style={{ fontWeight: 'bold' }}>Hang on</span> and we'll get
            you started in no time!
          </Title>
        </LoadingComponent>
      ) : (
        <Login onLogin={onUserLogin} />
      )}
    </MantineProvider>
  );
}

export default App;
