import { useEffect, useState } from 'react';
import { badges as staticBadges } from '../utils/badges';

// Cache badges API response here
const cache = new Map();

const useCustomBadges = (uid, token) => {
  const [badges, setBadges] = useState(cache.get("badges"));

  const fetchBadges = async (uid, token) => {
    if (process.env.NODE_ENV === 'development') {
      setBadges(staticBadges);
    } else {
      const res = await fetch("/api/badges", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'x-mg-authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ uid }),
      });
      const badges = await res.json();
      setBadges(badges);
    }
  };

  useEffect(() => {
    cache.set("badges", badges);
  }, [badges]);

  useEffect(() => {
    if (uid && token && !badges) {
      fetchBadges(uid, token);
    }
  }, [uid, token, badges]);

  return badges;
}

export default useCustomBadges;
