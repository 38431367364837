import React, { useEffect, useState } from 'react';
import { Modal, Stack, Checkbox, Title, Button } from '@mantine/core';
import LoadingComponent from '../Loader';
import { CONNECTION_OPTIONS } from '../../utils/badges';

export function ConnectionModal({
  opened,
  closeModal,
  connection,
  addConnection,
  isLoading,
  isError,
  isSuccess,
}) {

  const [value, setValue] = useState([]);

  useEffect(() => {
    const badges = (connection && connection.connection && connection.connection.badges) || [];
    setValue(badges);
  }, [connection]);


  const ModalContent = () => {
    if (isLoading)
      return (
        <LoadingComponent>
          {' '}
          <Title
            align='center'
            sx={{
              fontSize: 20,
              fontWeight: 300,
            }}
          >
            <span style={{ fontWeight: 'bold' }}>Hang on</span> as we fill up
            your social cup
          </Title>
        </LoadingComponent>
      );

    if (isError) return <ErrorComponent handleClose={closeModal} />;

    if (isSuccess) return <SuccessComponent handleComplete={closeModal} />;

    return (
      <>
        <Title order={2}>
          How did you connect with {connection.name}?{' '}
          <span role='img' aria-label={'thinking face'}>
            🤔
          </span>{' '}
        </Title>
        <Checkbox.Group value={value} onChange={setValue}>
          {CONNECTION_OPTIONS.map((option) => (
            <div
              style={{ display: 'flex', alignItems: 'flexStart', gap: 8 }}
              key={option.id}
            >
              <Checkbox 
                value={option.id}
                label={option.label}
                size='md'
                color='indigo'
              />
              <span role='img' aria-label={option.label}>
                {option.icon}
              </span>
            </div>
          ))}
        </Checkbox.Group>
        <Button onClick={() => addConnection(connection, { badges: value })}>
          Submit
        </Button>
      </>
    );
  };
  return (
    <Modal
      opened={opened}
      withCloseButton={false}
      centered
      onClose={closeModal}
    >
      <Stack>
        <ModalContent />
      </Stack>
    </Modal>
  );
}

const SuccessComponent = ({ handleComplete }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 24,
      }}
    >
      <Title>
        <span
          role='img'
          aria-label='hand shake'
          style={{
            fontSize: 56,
          }}
        >
          🤝
        </span>
      </Title>
      <Title
        align='center'
        sx={{
          fontSize: 20,
          fontWeight: 300,
        }}
      >
        You've made a{' '}
        <span style={{ fontWeight: 'bold' }}>successful connection </span>
      </Title>
      <Button
        onClick={handleComplete}
        bg='#D86E89'
        sx={{
          '&:hover': {
            background: '#d15474',
          },
        }}
      >
        Return
      </Button>
    </div>
  );
};

const ErrorComponent = ({ handleClose }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 24,
      }}
    >
      <Title>
        <span
          role='img'
          aria-label='oops face'
          style={{
            fontSize: 56,
          }}
        >
          😬
        </span>
      </Title>
      <Title
        align='center'
        sx={{
          fontSize: 20,
          fontWeight: 300,
        }}
      >
        <span style={{ fontWeight: 'bold' }}>Oops!</span> Mistakes can happen
        and something has gone wrong.
      </Title>
      <Button
        onClick={handleClose}
        bg='#D86E89'
        sx={{
          '&:hover': {
            background: '#d15474',
          },
        }}
      >
        Return
      </Button>
    </div>
  );
};
