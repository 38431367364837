import React, { useState } from 'react';
import {
  createStyles,
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import UserMenu from './UserMenu';

const HEADER_HEIGHT = 60;

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: 1,
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  heading: {
    '@media screen and (max-width: 315px)': {
      fontSize: '1.5rem'
    },
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({
        variant: 'light',
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor })
        .color,
    },
  },
}));

export function HeaderResponsive({ 
  links, 
  onTabChange, 
  name, 
  user, 
  uid, 
  token,
}) {
  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].tab);
  const { classes, cx } = useStyles();

  const items = links.map((link) => (
    <a
      key={link.label}
      href={link.tab}
      className={cx(classes.link, {
        [classes.linkActive]: active === link.tab,
      })}
      onClick={(event) => {
        event.preventDefault();
        onTabChange(link.tab);
        setActive(link.tab);
        close();
      }}
    >
      {link.label}
    </a>
  ));

  return (
    <>
      <Header height={HEADER_HEIGHT} mb={20} className={classes.root}>
        <Container className={classes.header} size='lg'>
          <Burger
            opened={opened}
            onClick={toggle}
            className={classes.burger}
            size='sm'
          />
          <Transition transition='pop-top-right' duration={200} mounted={opened}>
            {(styles) => (
              <Paper className={classes.dropdown} withBorder style={styles}>
                {items}
              </Paper>
            )}
          </Transition>

          <h1 className={classes.heading}>
            Hey <span style={{ fontWeight: 300 }}>{name.split(' ')[0]}</span> 👋
          </h1>

          <Group spacing={5} className={classes.links}>
            {items}
          </Group>

          <UserMenu 
            image={user.image_192} 
            name={user.name} 
            email={user.email}
            uid={uid}
            token={token}
          />
        </Container>
      </Header>
    </>
  );
}

export default HeaderResponsive;
